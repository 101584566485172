const config = {
  apiKey: 'AIzaSyDxqakzQ9nFEKtYqUBhF3ODXUlOH_lqRzc',
  authDomain: 'collar-app.firebaseapp.com',
  databaseURL: 'https://collar-app.firebaseio.com',
  projectId: 'collar-app',
  storageBucket: 'collar-app.appspot.com',
  messagingSenderId: '828930806626',
  appId: '1:828930806626:web:dae23d01267b91e7a9123b',
  measurementId: 'G-3BWK039X4T'
};

module.exports = config;
